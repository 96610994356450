import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  CompareValues,
  ProductCategoryDto,
  ProductPropertiesVm,
  ProductsVm,
  ProjectsVm,
  ProjectVm,
  SimilarProjectDto,
  SimilarProjectsVm
} from 'src/lib/api';
import type { AppThunk } from 'src/store';
import {
  CompareValueIndex,
  ProductIndex,
  SubProduct,
  Substitution
} from 'src/types/ezsub';
import { v4 as uuidv4 } from 'uuid';
import { FieldValue } from 'src/types/FieldValue';
import { ProjectStatus } from 'src/types/project';
import Fuse from 'fuse.js';

interface EzsubState {
  isLoaded: boolean;
  isProductsLoaded: boolean;
  isDirty: boolean;
  products: Array<ProductCategoryDto>;
  indexedProducts: Fuse<ProductIndex>;
  projects: Array<ProjectVm>;
  substitutions: Array<Substitution>;
  selectedProject: ProjectVm;
  selectedSubstitutionIndex: number;
  currentTab: number;
  projectStatus: ProjectStatus;
}

const createProductIndex = (products: ProductCategoryDto[]) => {
  var productIndex: ProductIndex[] = [];
  var selectedProduct: SubProduct;
  for (let i = 0; i < products.length && !selectedProduct; i++) {
    let category = products[i];
    for (let p = 0; p < category.products.length && !selectedProduct; p++) {
      let product = category.products[p];
      for (let s = 0; s < product.subProducts.length && !selectedProduct; s++) {
        let sub = product.subProducts[s];
        productIndex.push({
          tradeName: sub.name,
          selectedProduct: {
            productId: product.id,
            productName: product.name,
            subProductId: sub.id,
            subProductName: sub.name
          },
          selectedCategory: category,
          subProducts: category.products.flatMap((product) =>
            product.subProducts.map((subProduct) => ({
              productId: product.id,
              productName: product.name,
              subProductId: subProduct.id,
              subProductName: subProduct.name
            }))
          )
        });
      }
    }
  }
  //setup fuse index
  const indexedProducts = new Fuse(productIndex, {
    keys: ['tradeName'],
    threshold: 0.1
  });
  return indexedProducts;
};

const NewSubProduct = (): SubProduct => {
  return {
    productId: 0,
    productName: '',
    subProductId: 0,
    subProductName: ''
  };
};

const NewSub = (selected?: Boolean): Substitution => {
  return {
    id: uuidv4(),
    proposedSubstituion: '',
    modelNumber: '',
    specificTitle: '',
    competitorName: '',
    tradeName: '',
    section: '',
    page: '',
    article: '',
    category: '',
    productDescription: '',
    daySavings: null,
    dollarSavings: '',
    partsAffected: null,
    similarProjectName: '',
    similarProjectAddress: '',
    similarProjectCity: '',
    similarProjectState: '',
    similarProjectZip: '',
    similarProjectArchitect: '',
    similarProjectOwner: '',
    similarProjectYear: null,
    subProducts: [],
    compareValues: [],
    selectedSubProduct: {
      productId: 0,
      productName: '',
      subProductId: 0,
      subProductName: ''
    },
    selected: selected ?? false
  };
};

const initialState: EzsubState = {
  isLoaded: false,
  isProductsLoaded: false,
  isDirty: false,
  products: [],
  indexedProducts: createProductIndex([]),
  projects: [],
  substitutions: [NewSub(true)],
  selectedProject: null,
  selectedSubstitutionIndex: 0,
  currentTab: 0,
  projectStatus: ProjectStatus.EMPTY
};

const slice = createSlice({
  name: 'ezsub',
  initialState,
  reducers: {
    loading(state: EzsubState) {
      state.isLoaded = false;
    },
    getProducts(
      state: EzsubState,
      action: PayloadAction<ProductCategoryDto[]>
    ) {
      state.products = action.payload;
      state.indexedProducts = createProductIndex(action.payload);
      state.isProductsLoaded = true;
    },
    getProjects(state: EzsubState, action: PayloadAction<ProjectVm[]>) {
      state.projects = action.payload;
      state.isLoaded = true;
    },
    getProject(state: EzsubState, action: PayloadAction<ProjectVm>) {
      state.selectedProject = action.payload;
      //Map project substitutions into Substitiion type which has additional UI tracking properties
      if (action.payload.substitutions.length) {
        let subs = action.payload.substitutions.map((s, i) => {
          let sub = { ...NewSub(false), ...s } as Substitution;
          sub.selected = i === 0;
          //set product details
          if (s.tradeName && state.products.length > 0) {
            let result = state.indexedProducts.search(s.tradeName);
            if (result.length > 0) {
              const product = result[0].item;
              sub.category = product.selectedCategory.name;
              sub.selectedSubProduct = product.selectedProduct;
              sub.modelNumber = product.selectedProduct.productName;
              sub.subProducts = product.subProducts;
            } else {
              sub.category = '';
              sub.selectedSubProduct = NewSubProduct();
              sub.modelNumber = '';
              sub.subProducts = [];
            }
          }
          return sub;
        });
        state.substitutions = subs;
      } else {
        state.substitutions = [NewSub(true)];
      }
      state.projectStatus = ProjectStatus.LOADED;
    },
    getSimilarProject(
      state: EzsubState,
      action: PayloadAction<SimilarProjectDto>
    ) {
      state.substitutions[state.selectedSubstitutionIndex] = {
        ...state.substitutions[state.selectedSubstitutionIndex],
        similarProjectName: action.payload.projectName,
        similarProjectArchitect: action.payload.address,
        similarProjectOwner: action.payload.owner,
        similarProjectAddress: action.payload.address,
        similarProjectCity: action.payload.city,
        similarProjectState: action.payload.state,
        similarProjectZip: action.payload.zip,
        similarProjectYear: action.payload.year
      };
    },
    setTemplate(state: EzsubState, action: PayloadAction<ProjectVm>) {
      state.projectStatus = ProjectStatus.LOADED;
      action.payload.id = undefined;
      state.selectedProject = action.payload;
      if (action.payload.projectType === 'prebid') state.currentTab = 1;
      if (action.payload.projectType === 'postbid') state.currentTab = 2;
    },
    removeSubstitution(state: EzsubState, action: PayloadAction<Substitution>) {
      state.isDirty = true;
      let newSubs = state.substitutions.filter(
        (sub) => sub.id !== action.payload.id
      );
      if (newSubs.length === 0) {
        newSubs = [NewSub()];
      }
      state.substitutions = newSubs;
      state.selectedSubstitutionIndex = 0;
    },
    addSubstitution(state: EzsubState) {
      state.isDirty = true;
      const newSubstitution = NewSub(true);
      state.substitutions = [
        newSubstitution,
        ...state.substitutions.map((s) => {
          return { ...s, selected: false };
        })
      ];
      state.selectedSubstitutionIndex = 0;
    },
    selectSubstitution(state: EzsubState, action: PayloadAction<Substitution>) {
      state.substitutions = state.substitutions.map((s, index) => {
        if (s.id === action.payload.id) {
          //set selected index
          state.selectedSubstitutionIndex = index;
          return { ...s, selected: true };
        }
        return { ...s, selected: false };
      });
    },
    updateSubstitution(state: EzsubState, action: PayloadAction<FieldValue>) {
      state.isDirty = true;
      const { field, value } = action.payload;
      state.substitutions[state.selectedSubstitutionIndex] = {
        ...state.substitutions[state.selectedSubstitutionIndex],
        modelNumber:
          field === 'selectedSubProduct'
            ? value.productName
            : state.substitutions[state.selectedSubstitutionIndex].modelNumber,
        tradeName:
          field === 'selectedSubProduct'
            ? value.subProductName
            : state.substitutions[state.selectedSubstitutionIndex].tradeName,
        [field]: value
      };
    },
    updateCompareValues(
      state: EzsubState,
      action: PayloadAction<CompareValueIndex>
    ) {
      state.isDirty = true;
      const { index, value, isCompetitor } = action.payload;
      if (isCompetitor) {
        state.substitutions[state.selectedSubstitutionIndex].compareValues[
          index
        ].competitorValue = value;
      } else {
        state.substitutions[state.selectedSubstitutionIndex].compareValues[
          index
        ].value = value;
      }
    },
    saveProject(state: EzsubState, action: PayloadAction<ProjectVm>) {
      state.isDirty = false;
      action.payload.substitutions = state.substitutions;
      state.selectedProject = action.payload;
    },
    cleanProject(state: EzsubState) {
      state.isDirty = false;
    },
    changeTab(state: EzsubState, action: PayloadAction<number>) {
      state.currentTab = action.payload;
      // state.selectedProject = null;
    },
    resetProject(state: EzsubState) {
      state.projectStatus = ProjectStatus.EMPTY;
      state.selectedProject = null;
    },
    loadingProject(state: EzsubState) {
      state.projectStatus = ProjectStatus.LOADING;
      state.selectedProject = null;
    }
  }
});
//------------------------------------------------------------------

export const reducer = slice.reducer;

//------------------------------------------------------------------

export const getProducts = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loading());
  const { data: { productCategories } = {} } = await axios.get<ProductsVm>(
    '/api/products'
  );

  dispatch(slice.actions.getProducts(productCategories));
};

export const getProjects = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loading());
  const { data: { projects } = {} } = await axios.get<ProjectsVm>(
    '/api/projects'
  );

  dispatch(slice.actions.getProjects(projects));
};

export const getSimilarProject = (id: number): AppThunk => async (dispatch) => {
  const { data: { similarProjects } = {} } = await axios.get<SimilarProjectsVm>(
    `/api/products/${id}/similarprojects`
  );
  if (similarProjects.length > 0)
    dispatch(slice.actions.getSimilarProject(similarProjects[0]));
};

export const getProject = (id: number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loadingProject());
  const { data = null } = await axios.get<ProjectVm>(`/api/projects/${id}`);

  dispatch(slice.actions.getProject(data));
};

export const setTemplate = (id: number): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loadingProject());
  const { data = null } = await axios.get<ProjectVm>(`/api/projects/${id}`);

  dispatch(slice.actions.setTemplate(data));
};

export const getProductProperties = (id: number): AppThunk => async (
  dispatch
) => {
  const { data: { properties } = {} } = await axios.get<ProductPropertiesVm>(
    `api/products/${id}/properties`
  );

  if (properties) {
    let value = properties.map((prop) => {
      const values: CompareValues = {
        id: uuidv4(),
        name: prop.name,
        value: prop.defaultValue,
        competitorName: prop.name,
        competitorValue: '',
        sortId: prop.sortId
      };
      return values;
    });
    dispatch(
      slice.actions.updateSubstitution({ field: 'compareValues', value })
    );
  }
};

export const saveProject = (
  id: number,
  substitutions: Substitution[],
  callback: Function
): AppThunk => async (dispatch) => {
  try {
    var response = await axios.post(`/api/projects/${id}/substitutions`, {
      substitutions
    });
    if (response.status === 204) {
      dispatch(slice.actions.cleanProject());
      callback();
    } else {
      callback('Error saving project');
    }
  } catch (error) {
    callback('Error saving project');
  }
};

export const updateProject = (
  project: ProjectVm,
  callback: Function
): AppThunk => async (dispatch) => {
  var response = await axios.put(`/api/projects`, project);
  if (response.status === 200) {
    dispatch(slice.actions.saveProject(project));
    callback();
  } else {
    // Handle error case
    // You can dispatch an action to update the state with the error message
    // For example:
    // dispatch(slice.actions.setError('Failed to update project'));
  }
};

export const addSubstitution = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.addSubstitution());
};

export const removeSubstitution = (sub: Substitution): AppThunk => (
  dispatch
) => {
  dispatch(slice.actions.removeSubstitution(sub));
};

export const removeProject = (projectId: number): AppThunk => async (
  dispatch
) => {
  var response = await axios.delete(`/api/projects/${projectId}`);
  if (response.status === 204) {
    dispatch(slice.actions.loading());
    const { data: { projects } = {} } = await axios.get<ProjectsVm>(
      '/api/projects'
    );

    dispatch(slice.actions.getProjects(projects));
  } else {
    // Handle error case
    // You can dispatch an action to update the state with the error message
    // For example:
    // dispatch(slice.actions.setError('Failed to update project'));
  }
};

export const selectSubstitution = (sub: Substitution): AppThunk => (
  dispatch
) => {
  dispatch(slice.actions.selectSubstitution(sub));
};

export const updateSubstitution = (prop: FieldValue): AppThunk => (
  dispatch
) => {
  dispatch(slice.actions.updateSubstitution(prop));
};

export const updateCompareValues = (
  index: number,
  value: string,
  isCompetitor: boolean
): AppThunk => (dispatch) => {
  dispatch(slice.actions.updateCompareValues({ index, value, isCompetitor }));
};

export const changeTab = (tab: number): AppThunk => (dispatch) => {
  dispatch(slice.actions.changeTab(tab));
};

export const resetProject = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.resetProject());
};

export default slice;
