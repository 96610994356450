/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';

import type { User } from 'src/types/user';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import sha256 from 'crypto-js/sha256';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MemoryIcon from '@material-ui/icons/Memory';
import HomeIcon from '@material-ui/icons/Home';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import {
  Calendar as CalendarIcon,
  BarChart2 as BarChart2Icon,
  UserCheck as UserCheckIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  Users as UsersIcon,
  Truck as TruckIcon,
  Youtube as YoutubeIcon,
  Download as DownloadIcon,
  Settings as SettingsIcon,
  Triangle as TriangleIcon,
  // Wind as WindIcon,
  Maximize2 as MaximizeIcon,
  RefreshCcw as RefreshIcon
} from 'react-feather';
import {
  LienWaiver,
  MktMaterial,
  CareMaint,
  CustomOrder,
  InstallCert,
  ProjectInfo,
  StructuralCalculator,
  ProductSample
} from 'src/components/Icons';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  email?: string;
  hash?: string;
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

//toggle on a user property. (Is Distributor)
const ezestimate = (user: User) =>
  user.details.customer?.isDistributor
    ? {
        title: 'EZ-Estimate 47.5.D',
        href: '/static/docs/EZ_Estimate-47.5.D-CLIENT-10-25-24.xlsm',
        icon: SettingsIcon
      }
    : {
        title: 'EZ-Estimate 47.5',
        href: '/static/docs/EZ_Estimate-47.5-CLIENT-10-25-24.xlsm',
        icon: SettingsIcon
      };

//toggle on a user property. (Is Distributor)
const doorFab = (user: User) =>
  user.details.customer?.isDoorFab
    ? {
        title: 'F3 - Door Components',
        href: '/static/docs/F3-Section-10252024.pdf',
        icon: SettingsIcon
      }
    : null;

const doorFabDoorDesignManual = (user: User) =>
  user.details.customer?.isDoorFab
    ? {
        title: 'F3 - Door Design Manual',
        href: '/static/docs/DOOR_DESIGN_MANUAL.pdf',
        icon: DownloadIcon
      }
    : null;

const doorFabDoorFabricationTraining = (user: User) =>
  user.details.customer?.isDoorFab
    ? {
        title: 'F3 - Door Fabrication Training',
        href: '/static/docs/Door_Fabrication_Training.pptx',
        icon: DownloadIcon
      }
    : null;

const doorFabFrameDesignManual = (user: User) =>
  user.details.customer?.isDoorFab
    ? {
        title: 'F3 - Frame Design Manual',
        href: '/static/docs/FRAME_DESIGN_MANUAL.pdf',
        icon: DownloadIcon
      }
    : null;

const doorFabPS_INT810Dwg = (user: User) =>
  user.details.customer?.isDoorFab
    ? {
        title: 'F3 – INT810 Perimeter Sheet (DWG)',
        href: '/static/docs/PS_INT810.dwg',
        icon: DownloadIcon
      }
    : null;

const doorFabPS_INT810 = (user: User) =>
  user.details.customer?.isDoorFab
    ? {
        title: 'F3 – INT810 Perimeter Sheet (PDF)',
        href: '/static/docs/PS_INT810.pdf',
        icon: DownloadIcon
      }
    : null;

const doorFabIM_INT810 = (user: User) =>
  user.details.customer?.isDoorFab
    ? {
        title: 'F3 – INT810 Installation Manual',
        href: '/static/docs/IM_INT810.pdf',
        icon: DownloadIcon
      }
    : null;

const sections = (user: User): Section[] => [
  {
    subheader: '',
    items: [
      // {
      //   title: 'Dashboard',
      //   // icon: PieChartIcon,
      //   href: '/app/reports/dashboard'
      // },
      // {
      //   title: 'Finance Overview',
      //   // icon: BarChartIcon,
      //   href: '/app/reports/dashboard-alternative'
      // },
      {
        title: 'Home',
        href: '/app',
        icon: HomeIcon
      },
      {
        title: 'Orders',
        href: '/app/orders',
        icon: ReceiptIcon
      },
      {
        title: 'Shipments',
        href: '/app/shipments',
        icon: TruckIcon
      },
      {
        title: 'Messages',
        href: '/app/mail',
        icon: MailIcon
      },
      {
        title: 'Ordering & Estimating',
        items: [
          ezestimate(user),
          {
            title: 'Price Book',
            href: 'https://tubeliteusa.com/0814gi/',
            icon: DollarSignIcon
          },
          doorFab(user),
          {
            title: 'Door Quote / Order Form',
            href: '/static/docs/Door-Frame_OrderForm.zip',
            icon: MeetingRoomIcon
          },
          {
            title: 'Product Info',
            href: 'https://tubeliteusa.com/design-manual/',
            icon: MeetingRoomIcon
          },
          {
            title: 'Stock Door + Frame Order Form',
            href: '/static/docs/StockDoor-FrameOrderForm.xlsx',
            icon: MeetingRoomIcon
          },
          {
            title: 'Custom Order Checklist',
            href: '/static/docs/Tubelite_Custom_Order_Checklist.xls',
            icon: CustomOrder
          },
          {
            title: 'Product Translator Tool',
            href: 'https://www.tubeliteusa.com/compare',
            icon: DomainDisabledIcon
          },
          {
            title: 'Lien Waiver Request',
            href:
              'https://tubelite.pay.handle.com/auth/sso?redirect=request-waiver',
            icon: LienWaiver
          },
          {
            title: 'Project Info Sheet',
            href: '/static/docs/Tubelite_ProjectInfoSheet-Blank.doc',
            icon: ProjectInfo
          }
        ]
      },
      {
        title: 'Calculators & Tools',
        items: [
          {
            title: 'Deadload Calculator',
            href: '/app/calculator/deadload',
            icon: TriangleIcon
          },
          // {
          //   title: 'Windload Chart',
          //   href: '/app/calculator/windload',
          //   icon: WindIcon
          // },
          {
            title: 'Structural Calculator',
            href: '/app/calculator/structuralcalculator',
            icon: StructuralCalculator
          },
          {
            title: 'Glass Size (Old)',
            href:
              '/static/docs/Old%20Door%20Glass%20Size%20Calculator%20BEFORE%20SEPT%202024.xlsx',
            icon: MaximizeIcon
          },
          {
            title: 'Glass Size (New)',
            href: '/static/docs/Door%20Glass%20Size%20Calculator%201.03.xlsx',
            icon: MaximizeIcon
          },
          {
            title: 'EZ-SUB Product Substitution & Submittal Tool',
            href: '/app/ezsub',
            icon: RefreshIcon
          }
        ]
      },
      {
        title: 'Warranties',
        items: [
          {
            title: 'Sample Warranty',
            href: '/static/docs/Tubelite_WarrantySample.pdf',
            icon: VerifiedUserIcon
          },
          {
            title: 'Warranty Request Form',
            href: '/static/docs/Tubelite_WarrantyRequest.pdf',
            icon: VerifiedUserIcon
          },
          {
            title: 'Linetec Finish Warranty',
            href: '/static/docs/Tubelite_Linetec_FinishWarranty.pdf',
            icon: VerifiedUserIcon
          }
        ]
      },
      {
        title: 'Tubelite Information & Training',
        items: [
          {
            title: 'Tubelite Tours',
            href: '/app/info/tours',
            icon: YoutubeIcon
          },
          {
            title: 'Installation Videos',
            href: '/app/training/installation',
            icon: YoutubeIcon
          },
          {
            title: 'Continuing Education',
            href: 'https://teach.tubeliteusa.com/',
            icon: CalendarIcon
          },
          {
            title: 'Pricebook & Easy Estimate Training',
            href: '/static/docs/PB_EZ_Est_Training.pptx',
            icon: DownloadIcon
          },
          doorFabDoorDesignManual(user),
          doorFabDoorFabricationTraining(user),
          doorFabFrameDesignManual(user),
          doorFabPS_INT810Dwg(user),
          doorFabPS_INT810(user),
          doorFabIM_INT810(user)
        ]
      },
      {
        title: 'Resources',
        items: [
          {
            title: 'Finish Samples',
            href: 'https://linetec.com/tubelite-samples/',
            icon: MktMaterial
          },
          {
            title: 'Literature',
            href: '/app/resources/marketingmaterials',
            icon: MenuBookIcon
          },
          {
            title: 'Product Corner Samples',
            href: '/app/resources/productsamples',
            icon: ProductSample
          },
          {
            title: 'Installer Verification Letter',
            href: '/app/resources/InstallerVerification',
            icon: InstallCert
          },
          {
            title: 'Care and Maintenance of Architectural Aluminum',
            href:
              'https://www.tubeliteusa.com/wp-content/uploads/2019/09/Care-of-Finished-Aluminum-Guide_Linetec_web.pdf',
            icon: CareMaint
          },
          {
            title: 'Pay Online',
            href: 'https://www.unitedtranzactions.com/obp/tubelite',
            icon: MonetizationOnIcon
          }
        ]
      },
      {
        title: 'Storefront and Entrance Redesign Resources',
        items: [
          {
            title: 'Entrance old/new parts list',
            href: '/static/docs/Entrance%20old%20to%20new%20parts%20list.xlsx',
            icon: MenuBookIcon
          },
          {
            title: 'Storefront old/new parts list',
            href:
              '/static/docs/Storefront%20old%20to%20new%20parts%20list.xlsx',
            icon: MenuBookIcon
          },
          {
            title: 'Questions & Answers',
            href:
              '/static/docs/QA-Storefront%20and%20Entrance%20Redesign_10-25-24.pdf',
            icon: MenuBookIcon
          },
          {
            title: 'Video: Product Training and Q&A',
            href: 'https://vimeo.com/1022955321/8e5b9c6539',
            icon: YouTubeIcon
          },
          {
            title: 'Video: Price Book & Estimating Tools',
            href: 'https://vimeo.com/1033640312/d9412075a8?share=copy',
            icon: YouTubeIcon
          }
        ]
      }
    ]
  },
  {
    subheader: 'Administration',
    items: [
      {
        title: 'Auth0',
        href: 'https://manage.auth0.com/dashboard/us/dev-tubelite/users',
        icon: UsersIcon
      },
      {
        title: 'Customers',
        href: '/app/admin/customers',
        icon: UsersIcon
      },
      {
        title: 'Users',
        href: '/app/admin/users',
        icon: UsersIcon
      },
      {
        title: 'Metrics',
        href: 'https://manage.auth0.com/dashboard/us/dev-tubelite/',
        icon: BarChart2Icon
      },
      {
        title: 'Form Submissions',
        href: '/app/admin/forms',
        icon: ListAltIcon
      },
      {
        title: 'Calculator Admin',
        href: '/app/admin/calculator',
        icon: MemoryIcon
      },
      {
        title: 'Update Customers',
        href: '/app/admin/updatecustomers',
        icon: UserCheckIcon
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  email,
  hash,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  email: string;
  hash: string;
  depth?: number;
}) {
  return (
    <List disablePadding key={hash}>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, email, hash, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  email,
  hash,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  email: string;
  hash: string;
  depth: number;
}) {
  if (!item) return acc;

  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          email,
          hash,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const [hash, setHash] = useState('');

  useEffect(() => {
    if (user) {
      const result = sha256(`${user.email}Ag53Cd9DsX`).toString();
      setHash(result);
      if (openMobile && onMobileClose) {
        onMobileClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {user ? (
          <>
            <Hidden lgUp>
              <Box p={2} display="flex" justifyContent="center">
                <RouterLink to="/">
                  <Logo />
                </RouterLink>
              </Box>
            </Hidden>
            <Box p={2}>
              <Box display="flex" justifyContent="center">
                <RouterLink to="/app/account">
                  <Avatar
                    alt="User"
                    className={classes.avatar}
                    src={user.avatar}
                  />
                </RouterLink>
              </Box>
              <Box mt={2} textAlign="center">
                <Link
                  component={RouterLink}
                  to="/app/account"
                  variant="h5"
                  color="textPrimary"
                  underline="none"
                >
                  {user.name}
                </Link>
                <Typography variant="body2" color="textSecondary">
                  role:{' '}
                  <Link component={RouterLink} to="#">
                    {user.roles}
                  </Link>
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  Customer Number:{' '}
                  <Link component={RouterLink} to="#">
                    {user.details &&
                      user.details.customer &&
                      user.details.customer?.id}
                  </Link>
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  {user.details &&
                    user.details.customer &&
                    user.details.customer?.name}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box p={2}>
              {sections(user).map(
                (section) =>
                  (section.subheader !== 'Administration' ||
                    (section.subheader === 'Administration' &&
                      user.roles.includes('Administrator'))) && (
                    <List
                      key={section.subheader}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {section.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: section.items,
                        pathname: location.pathname,
                        email: user.email,
                        hash
                      })}
                    </List>
                  )
              )}
            </Box>
            <Divider />
          </>
        ) : (
          <></>
        )}
        ;
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
